import React, { useCallback, useState } from "react";
import { Header } from "../../../components";
import AdminUserHistoryStyles from "./AdminUserHistoryStyles";
import { EmptyHistory } from "./EmptyHistory";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { apiService } from "../../../services/api/api.service";
import {
  useHistory,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
import { LoadingHistoryItems } from "../../UserPages/HistoryList/LoadingHistoryItems";
import { queryBuilder } from "../../../utils/queryBuilder";
import { useDispatch, useSelector } from "react-redux";
import { setAdminUserData } from "../../../actions/admin/admin.actions";
import { AdminUserHistoryContent } from "./AdminUserHistoryContent/AdminUserHistoryContent";
import { isEmpty } from "../../../utils/helpers/isEmpty";
import AdminUserHistoryContentStyles from "./AdminUserHistoryContent/AdminUserHistoryContentStyles";
import { AdminUserHistoryHeader } from "./AdminUserHistoryContent/AdminUserHistoryHeader";
import { useTranslation } from "react-i18next";

const AdminUserHistory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [foundUser, setFoundUser] = useState(null);

  const { adminSingleUserData, filters } = useSelector(state => state.admin);

  const { id: userId } = useParams();

  const getUserInfo = useCallback(
    async userId => {
      try {
        setIsLoading(true);
        const response = await apiService.get(`/users/${userId}`);
        setFoundUser(response.data);
      } catch (error) {
        toast.error(t("failed_to_get_user_info"));
      } finally {
        setIsLoading(false);
      }
    },
    [userId, isLoading]
  );

  const getAdminHistoryInfo = useCallback(
    async (userId, filters) => {
      setIsLoading(true);

      try {
        const formattedOptionsFilter = filters.type
          .map(option => {
            return option.name.toLowerCase();
          })
          .join(",");

        const query = queryBuilder({
          baseQuery: `/users/admin/history/${userId}`,
          queryObject: {
            page: filters.page,
            limit: filters.perPage,
            type: formattedOptionsFilter
          }
        });
        const response = await apiService.get(query);
        dispatch(setAdminUserData(response.data));
      } catch (error) {
        toast.error(t("failed_to_get_user_info"));
      } finally {
        setIsLoading(false);
      }
    },
    [userId, filters]
  );

  useEffect(() => {
    getUserInfo(userId);
  }, [userId]);

  useEffect(() => {
    getAdminHistoryInfo(userId, filters);
  }, [userId, filters]);

  const ShownContent = () => {
    if (isLoading) return <LoadingHistoryItems isLoading={isLoading} />;

    if (!isEmpty(adminSingleUserData)) {
      return (
        <AdminUserHistoryContentStyles>
          <AdminUserHistoryHeader tokens={adminSingleUserData.tokens} />
          <AdminUserHistoryContent
            adminSingleUserData={adminSingleUserData}
            filters={filters}
          />
        </AdminUserHistoryContentStyles>
      );
    }

    return <EmptyHistory />;
  };

  const shouldRedirectToOriginal = () => history.push("/users");
  return (
    <AdminUserHistoryStyles>
      <div className="activityContainerHistory">
        <Header
          shouldRedirectToOriginal={shouldRedirectToOriginal}
          headerTitle={`${foundUser?.firstName || ""} User History`}
        />
        <div className="activityContent">
          <div className="activityHistoryWrapper">{ShownContent()}</div>
        </div>
      </div>
    </AdminUserHistoryStyles>
  );
};

export default AdminUserHistory;
