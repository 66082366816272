import { convertEditorStateToString } from "../../textEditor.utils";
import { apiService } from "../api.service";

export const sendMail = async values => {
  const payload = {
    ...values,
    message: convertEditorStateToString(values.message)
  };
  return await apiService.post("/users/send-email", payload);
};

/**
 * Sends budget reminder emails to all employees.
 */
export const sendBudgetRemindersEmails = async () => {
  return apiService.post("/users/send-budget-reminders");
};

export const handleSendUsageInstructions = async () => {
  return apiService.post("/users/send-usage-instructions");
};
