import styled from "styled-components";

const TreeAnimationStyles = styled.div`
  background-image: linear-gradient(to bottom, #1488cc 3%, #1e3c72);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;

  @media screen and (min-width: 876px) {
    display: flex;
  }

  svg {
    width: 10rem;
    height: 10rem;
  }

  .snow-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
  }

  .snowflake {
    position: absolute;
    background: white;
    border-radius: 50%;
    opacity: 0.8;
  }

  @keyframes fall {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100vh);
    }
  }

  @keyframes diagonal-fall {
    0% {
      transform: translateY(0) translateX(0);
    }
    100% {
      transform: translateY(100vh) translateX(20vw);
    }
  }
`;

export default TreeAnimationStyles;
