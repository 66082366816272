import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";

// Validations
import { validateLogin } from "../../../services/validations/publicPages/loginFormValidation";

// Components
import { LoginFormStyles } from "./LoginFormStyles";
import { BenefitLogo } from "../../../assets/Icons";
import { Button, CheckboxInput } from "../../";
import Unmasked from "../../../assets/images/002-visibility.png";
import Masked from "../../../assets/images/003-visibility-1.png";

// Services
import * as actionCreators from "../../../actions/app.actions";
import * as api from "../../../services/api/auth.service";

// Utils
import { isAuthenticatedRole } from "../../../services/roles.utils";

// Constants
import { UserRolesKeysEnum } from "../../../constants/roles.constants";
import { useTranslation } from "react-i18next";
import { LanguageDropdown } from "../../locales/LanguageDropdown";
import SnowAnimation from "../../ChristmasAnimation/ChristmasAnimation";

const LoginForm = ({ login, setIsBenefitLogin }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [maskedNewPassword, setMaskedNewPassword] = useState(true);

  const handleLogin = async values => {
    const response = await api.login(values);
    if (response.hasError) {
      return toast.error(
        response.errorMessage ? response.errorMessage : t("failed_to_login")
      );
    }
    window.location.reload();

    if (
      isAuthenticatedRole(
        response.user.authenticatedRole,
        UserRolesKeysEnum.HR
      ) &&
      !response.user.isOnboardingDone
    ) {
      history.push("/onboarding");
    }
    login(response.user);

    const metadata = {
      user_id: response.user.id,
      email: response.user.email,
      name: `${response.user.firstName} ${response.user.lastName}`,
      created_at: new Date()
    };
    window.Intercom("trackEvent", "user-login", metadata);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false
    },
    validate: validateLogin,
    onSubmit: handleLogin
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur
  } = formik;

  return (
    <LoginFormStyles>
      <div className="authWindow">
        <div className="lngDropdown">
          <LanguageDropdown />
        </div>
        <BenefitLogo color="#1568bf" />
        <div className="formContainer">
          <form onSubmit={handleSubmit} className="form" autoComplete="off">
            <label className="label" htmlFor="email">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                Email:
                <div className="errorMsg">
                  {errors.email && touched.email ? errors.email : ""}
                </div>
              </div>
              <input
                className="inputs"
                id="email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </label>

            <label className="label" htmlFor="password">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                {t("Password")}:
                <div className="errorMsg">
                  {errors.password && touched.password ? errors.password : ""}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <input
                  className="inputs"
                  id="password"
                  name="password"
                  type={maskedNewPassword ? "password" : "text"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <img
                  src={maskedNewPassword ? Masked : Unmasked}
                  onClick={() => setMaskedNewPassword(!maskedNewPassword)}
                  alt="masked"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: -30
                  }}
                />
              </div>
            </label>

            <div className="checkbox-wrapper">
              <CheckboxInput
                name="remember"
                value={values.remember}
                isChecked={values.remember}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <label className="label" htmlFor="remember">
                {t("remember_me")}
              </label>
            </div>

            <div className="buttonContainer">
              <div>
                <Link to="/forgot-password" className="forgotPassword">
                  {t("forgot_password")}
                </Link>
              </div>
              <Button type="submit" margin="0" padding="10px 22px">
                {t("Login")}
              </Button>
            </div>
            <div className="goBackContainer">
              <div onClick={() => setIsBenefitLogin(false)}>{t("go_back")}</div>
            </div>
          </form>
        </div>
      </div>
      {/* <div className="bannerWindow" /> */}
      <SnowAnimation />
    </LoginFormStyles>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    login: payload => dispatch(actionCreators.login(payload))
  };
};

export default connect(null, mapDispatchToProps)(LoginForm);
