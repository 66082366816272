import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Header } from "../../../components";
import PreviewHRBenefitStyles from "./PreviewHRBenefitStyles";
import { PreviewHRBenefitBody } from "./PreviewHRBenefitBody/PreviewHRBenefitBody";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const PreviewProviders = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const benefitGroup = useSelector(
    state => state.benefits.editPageState.benefitGroup
  );
  const shouldRedirectToOriginal = () => history.push("/providers");

  return (
    <PreviewHRBenefitStyles>
      <div className="previewHrBenefitsContainer">
        <Header
          headerTitle={t("preview")}
          shouldRedirectToOriginal={shouldRedirectToOriginal}
        />
        <PreviewHRBenefitBody benefitGroup={benefitGroup} />
      </div>
    </PreviewHRBenefitStyles>
  );
};
