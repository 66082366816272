import React from "react";
import BenefitGroupBenefitPreviewListStyles from "./BenefitGroupBenefitPreviewListStyles";
import { BenefitGroupBenefitPreviewItem } from "./BenefitGroupBenefitPreviewItem";

export const BenefitGroupBenefitPreviewList = props => {
  const { benefitGroup } = props;

  return (
    <BenefitGroupBenefitPreviewListStyles>
      {benefitGroup.benefits.length > 0 ? (
        benefitGroup.benefits.map(benefit => {
          return (
            <BenefitGroupBenefitPreviewItem
              benefit={benefit}
              key={benefit.id}
            />
          );
        })
      ) : (
        <></>
      )}
    </BenefitGroupBenefitPreviewListStyles>
  );
};
