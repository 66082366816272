import React, { useEffect } from "react";
import BenefitGroupBenefitPreviewStyles from "./BenefitGroupBenefitPreviewStyles";
import { useDispatch, useSelector } from "react-redux";
import { setChosenBenefitGroup } from "../../../../actions/employee/employeeBenefits.actions";
import * as benefitGroupService from "../../../../services/api/admin/benefitGroups.service";
import { isEmpty } from "../../../../services/general.utils";
import { useTranslation } from "react-i18next";
import { BenefitGroupBenefitPreviewList } from "./BenefitGroupBenefitPreviewList/BenefitGroupBenefitPreviewList";

export const BenefitGroupBenefitPreview = params => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { benefitGroupId } = params;

  const benefitGroup = useSelector(
    state => state.benefits.editPageState.benefitGroup
  );

  const getBenefitGroupForPreview = async () => {
    const response = await benefitGroupService.getBenefitGroupForPreview(
      benefitGroupId
    );

    if (response.hasError) {
      return toast.error(response.errorMessage);
    }

    dispatch(setChosenBenefitGroup(response));
  };

  useEffect(() => {
    getBenefitGroupForPreview();
  }, []);

  return (
    <BenefitGroupBenefitPreviewStyles>
      <div className="benefitGroupBenefitPreviewWrapper">
        {!isEmpty(benefitGroup) ? (
          <BenefitGroupBenefitPreviewList benefitGroup={benefitGroup} />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "40px",
              alignItems: "center"
            }}
          >
            {t("no_result_found")}.
          </div>
        )}
      </div>
    </BenefitGroupBenefitPreviewStyles>
  );
};
