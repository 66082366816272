import styled from "styled-components";

const BenefitGroupBenefitPreviewListStyles = styled.section`
  padding: 15px;

  // Styles for single item
  .benefitGroupPreviewItemWrapper {
    display: flex;
    flex-direction: column;
    &:first-child {
      border-top: 0.5px solid #f0f0f0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .benefitGroupPreviewItem {
      cursor: pointer;
      height: 50px;
      background: transparent;
      border: 0.5px solid #f0f0f0;
      display: flex;
      align-items: center;
      position: relative;

      .benefitGroupPreviewItemArrow {
        display: flex;
        width: 60px;
        align-items: center;
        justify-content: center;

        svg {
          width: 25px;
          height: 25px;
        }
      }

      .benefitGroupPreviewItemTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #5f5f5f;
      }

      .benefitGroupPreviewItemPrice {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #5f5f5f;
        position: absolute;
        right: 20px;
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
        }
      }
    }

    .benefitGroupPreviewItemDropdown {
      padding: 10px;
      font-size: 14px;
    }
  }
`;

export default BenefitGroupBenefitPreviewListStyles;
