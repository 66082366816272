import styled from "styled-components";

const BulletinNotificationStyle = styled.div`
  width: 400px;
  padding: 0;
  max-height: 90vh;
  height: 57vh;

  .bulletin-notification {
    width: 100%;
    height: 100%;

    &__header {
      color: white;
      padding: 20px;
      background-color: #1568bf;
      font-family: "arial", "helvetica neue", helvetica, arial, sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: bold;
      color: #ffffff;
    }

    &__body {
      font-size: 16px;
      font-family: "arial", "helvetica neue", helvetica, arial, sans-serif;
      line-height: 20px;
      color: #777777;
      padding: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      font-weight: 500;
    }

    &__footer {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: "arial", "helvetica neue", helvetica, arial, sans-serif;
      line-height: 20px;
      color: #777777;

      position: absolute;
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default BulletinNotificationStyle;
