import styled from "styled-components";

const BenefitGroupBenefitPreviewStyles = styled.section`
  padding: 0 30px;
  margin-top: 60px;

  .benefitGroupBenefitPreviewWrapper {
    border-radius: 4px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  }
`;

export default BenefitGroupBenefitPreviewStyles;
