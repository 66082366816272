import React from "react";
import { isEmpty } from "../services/general.utils";

/**
 * Renders list of benefit group locations.
 * @param {City[]} locations
 * @param {boolean} isRemote
 * @param {City[]} employeeLocations
 * @returns {HTMLParagraphElement | undefined}
 */
export const renderLocations = (
  locations,
  isRemote,
  employeeLocations = []
) => {
  if (isEmpty(locations) && isRemote) {
    return <p className="locations">Remote</p>;
  }

  if (!isEmpty(locations) && isEmpty(employeeLocations) && isRemote) {
    return (
      <p className="locations">
        Remote,{" "}
        {locations.map((city, index) => {
          return locations.length - 1 === index
            ? `${city.name}`
            : `${city.name}, `;
        })}
      </p>
    );
  }

  if (!isEmpty(locations) && !isEmpty(employeeLocations)) {
    const employeeCity = employeeLocations?.find(city =>
      locations.find(ct => ct.id === city.id)
    );
    const groupLocations = !isEmpty(employeeCity)
      ? locations.filter(location => location.id !== employeeCity.id)
      : locations;

    if (!isEmpty(employeeCity) && isRemote) {
      return (
        <p className="locations">
          Remote, {employeeCity.name}{" "}
          {!isEmpty(groupLocations)
            ? `, ${groupLocations.map(city => city.name).join(", ")}`
            : null}
        </p>
      );
    }

    if (!isEmpty(employeeCity) && !isRemote) {
      return (
        <p className="locations">
          {employeeCity.name}{" "}
          {!isEmpty(groupLocations)
            ? `, ${groupLocations.map(city => city.name).join(", ")}`
            : null}
        </p>
      );
    }

    if (isEmpty(employeeCity) && isRemote) {
      return (
        <p className="locations">
          Remote,{" "}
          {!isEmpty(groupLocations)
            ? groupLocations.map(city => city.name).join(", ")
            : null}
        </p>
      );
    }

    if (isEmpty(employeeCity) && !isRemote) {
      return (
        <p className="locations">
          {!isEmpty(groupLocations)
            ? groupLocations.map(city => city.name).join(", ")
            : null}
        </p>
      );
    }
  }

  return (
    !isEmpty(locations) && (
      <p className="locations">{locations.map(city => city.name).join(", ")}</p>
    )
  );
};
