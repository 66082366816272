import React, { useState } from "react";
import { calculateValueWithVAT } from "../../../../../utils/calculateValueWithVAT";
import { ChevronIconDownSvg } from "../../../../../assets/Icons";
import {
  transformTokenCurrencyIcons,
  transformTokensToRsd
} from "../../../../../utils/transformTokensToRsd";
import ReactHtmlParser from "react-html-parser";

export const BenefitGroupBenefitPreviewItem = props => {
  const { benefit } = props;

  const [isOpen, setIsOpen] = useState(false);

  const sortVouchers = vouchersList => {
    const priceOfVouchers = vouchersList.map(voucher => {
      return transformTokensToRsd(
        calculateValueWithVAT(voucher.price, voucher.vatIncluded)
      );
    });

    if (priceOfVouchers.length === 1) return `${priceOfVouchers[0]}`;
    else {
      const sumOfVouchers = priceOfVouchers.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );

      if (sumOfVouchers === 0) return "0";

      const priceValues = priceOfVouchers.sort();

      return `${priceValues[0]} - ${priceValues[priceValues.length - 1]}`;
    }
  };

  return (
    <div className="benefitGroupPreviewItemWrapper">
      <button
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setIsOpen(state => !state);
        }}
        className="benefitGroupPreviewItem"
        type="button"
      >
        <div className="benefitGroupPreviewItemArrow">
          <ChevronIconDownSvg
            isOpen={isOpen}
            fill={isOpen ? "#668CCC" : "#DBDBDB"}
          />
        </div>
        <div className="benefitGroupPreviewItemTitle">{benefit.title}</div>
        <div className="benefitGroupPreviewItemPrice">
          {sortVouchers(benefit.vouchers)}
          <span>{transformTokenCurrencyIcons()}</span>
        </div>
      </button>
      {isOpen && (
        <div className="benefitGroupPreviewItemDropdown">
          {benefit.description ? ReactHtmlParser(benefit.description) : "/"}
        </div>
      )}
    </div>
  );
};
