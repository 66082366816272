import LOCALES from "../../../../constants/locales.constants";

export const relationshipOptions = [
  {
    id: 1,
    name: LOCALES[localStorage.getItem("locales") || "rs"]["single"],
    value: "Single"
  },
  {
    id: 2,
    name: LOCALES[localStorage.getItem("locales") || "rs"]["divorced"],
    value: "Divorced"
  },
  {
    id: 3,
    name: LOCALES[localStorage.getItem("locales") || "rs"]["married"],
    value: "Married"
  }
];
