import React from "react";
import PreviewHRBenefitStyles from "./PreviewHRBenefitStyles";
import { Header } from "../../../components";
import { useTranslation } from "react-i18next";
import { PreviewHRBenefitBody } from "./PreviewHRBenefitBody/PreviewHRBenefitBody";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PreviewHRBenefit = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const benefitGroup = useSelector(
    state => state.benefits.editPageState.benefitGroup
  );

  const shouldRedirectToOriginal = () => history.goBack();

  return (
    <PreviewHRBenefitStyles>
      <div className="previewHrBenefitsContainer">
        <Header
          shouldRedirectToOriginal={shouldRedirectToOriginal}
          headerTitle={t("benefit_preview", { name: "" })}
        />
        <PreviewHRBenefitBody benefitGroup={benefitGroup} />
      </div>
    </PreviewHRBenefitStyles>
  );
};

export default PreviewHRBenefit;
