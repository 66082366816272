import React, { useEffect, useState } from "react";
import { Modal } from "../modal";
import BulletinNotificationStyle from "./BulletinNotificationStyle";
import { useSelector } from "react-redux";

export const BulletinNotification = () => {
  const bulletValue = localStorage.getItem("bullet-board");
  const [isModalOpen, setIsModalOpen] = useState();

  const { companyId } = useSelector(state => state.app.user);

  useEffect(() => {
    if (!bulletValue) {
      localStorage.setItem("bullet-board", true);
      setIsModalOpen(true);
    }
  }, []);

  const closeModal = () => {
    localStorage.setItem("bullet-board", false);
    setIsModalOpen(false);
  };

  return (
    <>
      {companyId === 23 && bulletValue === "true" && (
        <Modal closeModal={closeModal}>
          <BulletinNotificationStyle>
            <div className="bulletin-notification">
              <div className="bulletin-notification__header">
                <h3>Benefiti</h3>
              </div>
              <div className="bulletin-notification__body">
                <p>
                  Obaveštavamo vas da će{" "}
                  <b>
                    <u>Refundacija</u>
                  </b>{" "}
                  biti nedostupna tokom decembra.
                </p>
              </div>

              <div className="bulletin-notification__footer">
                <p
                  style={{
                    marginTop: "20px",
                    fontSize: "20px",
                    fontWeight: "600"
                  }}
                >
                  Benefiti
                </p>
              </div>
            </div>
          </BulletinNotificationStyle>
        </Modal>
      )}
    </>
  );
};
