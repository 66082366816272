import LOCALES from "../../../../constants/locales.constants";

export const genderOptions = [
  {
    id: 1,
    name: LOCALES[localStorage.getItem("locales") || "rs"]["male"],
    value: "Male"
  },
  {
    id: 2,
    name: LOCALES[localStorage.getItem("locales") || "rs"]["female"],
    value: "Female"
  },
  {
    id: 3,
    name: LOCALES[localStorage.getItem("locales") || "rs"]["other"],
    value: "Other"
  }
];
